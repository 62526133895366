import {Component, Inject, TemplateRef} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {

  _prompt: string;

  _injectTemplate: TemplateRef<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    prompt: string,
    injectTemplate: TemplateRef<any>
  }) {
    this._prompt = data.prompt;
    this._injectTemplate = data.injectTemplate;
  }

}
