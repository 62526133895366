import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {APP_VERSION} from '../../version';
import {DialogService} from "../services/dialog.service";

@Injectable()
export class HttpVersionInterceptor implements HttpInterceptor {

    versionNotifications = {};

    constructor(private dialogService: DialogService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const params = new HttpParams({fromString: req.params.toString()});
        req = req.clone({
            params: params
                .append('_t', (new Date()).getTime() + '')
                .append('_v', APP_VERSION),
        });

        return next.handle(req)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        const response = event as HttpResponse<any>;
                        const version = response.headers.get('X-App-Version');
                        if (!!version && version !== APP_VERSION && version !== 'HEAD-SNAPSHOT') {
                            if (this.versionNotifications[version]) {
                                return;
                            }
                            console.error(`Version mismatch: ${APP_VERSION} : ${version}`);
                            this.notifyVersionMismatch(version);
                        }
                    }
                    return event;
                })
            );
    }

    private notifyVersionMismatch(serverVersion: string) {
        if (this.versionNotifications[serverVersion])
            return;
        this.dialogService.prompt(
            `
                <div style="display: flex; align-items: center">
                    <div style="flex: 1"></div>
                    <div class="cp-text-strong cp-text-medium cp-text-danger">Página Desatualizada</div>
                    <div style="flex: 1"></div>
                </div>
                <div>&nbsp;</div>
                <div>Está a utilizar uma versão desatualizada da página <b>Ponto.IPB</b>.</div>
                <div>&nbsp;</div>
                <div>Deseja atualizar?</div>
            `, yesNo => {
                if (yesNo)
                    window.location.reload();
            });
        this.versionNotifications[serverVersion] = true;
    }

}
