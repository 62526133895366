<!--<pre>{{extraEvents | json}}</pre>-->

<mwl-calendar-week-view
  #weekView
  [dayStartHour]="dayStartHour"
  [dayEndHour]="dayEndHour"
  [events]="allEvents"
  [viewDate]="viewDate"
  [refresh]="calendarRefresh"
  (eventClicked)="handleEventClick($event)"

  (hourSegmentClicked)="handleSegmentClicked($event)"
  [eventTemplate]="semanaEventTemplate"
  [tooltipTemplate]="semanaEventTooltipTemplate"

  tooltipPlacement="top"
></mwl-calendar-week-view>
<!--  hourSegments="1.5"-->

<ng-template
  #semanaEventTooltipTemplate
  let-contents="contents"
  let-placement="placement"
  let-event="event">

    <div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
        <div class="cal-tooltip-arrow"></div>
        <div class="cal-tooltip-inner">
            <div class="semana-turno-terminal-list">
                <div><i class="fa fa-map-pin"></i> Terminais Admitidos:</div>
                <div *ngFor="let t of event.turno.terminalList">{{t.name}}</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
  #semanaEventTemplate
  let-weekEvent="weekEvent"
  let-eventClicked="eventClicked"

  let-tooltipPlacement="tooltipPlacement"
  let-tooltipEvent="tooltipEvent"
  let-tooltipTemplate="tooltipTemplate"
  let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDelay="tooltipDelay"
  let-tooltipDisabled="tooltipDisabled"

  let-column="column"
  let-daysInWeek="daysInWeek"
  let-wrapper="weekEvent.event.meta">
    <ng-container [ngSwitch]="wrapper.templateName">
        <ng-container *ngIf="weekEvent.event.allDay">
            <div class="cal-event cal-ferias-event">
                <span class="cal-event-title" [innerHTML]="weekEvent.event.title"></span>
                <span class="cp-text-muted" *ngIf="wrapper.data.meioDiaPeriodo"> ({{wrapper.data.meioDiaPeriodo | nomePeriodoDia}})</span>
            </div>
        </ng-container>
        <ng-container>
            <app-horario-week-turno-event
                    [mwlCalendarTooltip]="wrapper.tooltip"
                    [tooltipPlacement]="tooltipPlacement"
                    [tooltipTemplate]="tooltipTemplate"
                    [tooltipEvent]="wrapper"
                    [tooltipAppendToBody]="tooltipAppendToBody"
                    [tooltipDelay]="tooltipDelay"
                    (mwlClick)="eventClicked.emit({ sourceEvent: $event })"
                    *ngSwitchCase="'turno'"
                    [wrapper]="wrapper" [selected]="selectionModel.isSelected(weekEvent.event.id)"></app-horario-week-turno-event>
            <app-horario-week-justificacao-event
                    [mwlCalendarTooltip]="wrapper.tooltip"
                    [tooltipPlacement]="tooltipPlacement"
                    [tooltipTemplate]="tooltipTemplate"
                    [tooltipEvent]="wrapper"
                    [tooltipAppendToBody]="tooltipAppendToBody"
                    [tooltipDelay]="tooltipDelay"
                    (mwlClick)="eventClicked.emit({ sourceEvent: $event })"
                    *ngSwitchCase="'justificacao'"
                    [wrapper]="wrapper" [selected]="selectionModel.isSelected(weekEvent.event.id)"></app-horario-week-justificacao-event>
            <!--        <app-horario-week-ferias-event-->
            <!--                [mwlCalendarTooltip]="wrapper.tooltip"-->
            <!--                [tooltipPlacement]="tooltipPlacement"-->
            <!--                [tooltipTemplate]="tooltipTemplate"-->
            <!--                [tooltipEvent]="wrapper"-->
            <!--                [tooltipAppendToBody]="tooltipAppendToBody"-->
            <!--                [tooltipDelay]="tooltipDelay"-->
            <!--                (mwlClick)="eventClicked.emit({ sourceEvent: $event })"-->
            <!--                *ngSwitchCase="'ferias'"-->
            <!--                [wrapper]="wrapper" [selected]="selectionModel.isSelected(weekEvent.event.id)"></app-horario-week-ferias-event>-->
        </ng-container>
    </ng-container>
</ng-template>
