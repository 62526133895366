<div mat-dialog-content>
    <div style="display: flex; align-items: center; margin: 1em 1em">
        <div style="flex: 1"></div>
        <div style="text-align: center">
            <div class="cp-text-large cp-text-strong">Ponto.IPB</div>
        </div>
        <div style="flex: 1"></div>
    </div>
    <div style="display: flex; align-items: center; font-size: 0.8em">
        <div style="flex: 1"></div>
        <div>v{{appVersion}}</div>
        <div style="flex: 1"></div>
    </div>
    <form (submit)="login()" [formGroup]="form">
        <div style="display: flex; align-items: center; flex-flow: column;">
            <mat-form-field style="width: 100%" appearance="outline">
                <input autofocus aria-label="Username" type="text" matInput placeholder="Utilizador" name="username" [formControl]="username"/>
                <mat-hint *ngIf="error" class="cp-text-danger"><i class="fa fa-lock"></i> {{error?.error.message}}</mat-hint>
            </mat-form-field>
            <mat-form-field style="width: 100%" appearance="outline">
                <input aria-label="Password" type="password" matInput placeholder="Palavra-passe" name="password" [formControl]="password"/>
            </mat-form-field>
            <div style="display: flex; align-items: center; margin-top: 1em; width: 100%">
                <div style="flex: 1"></div>
                <div>&nbsp;</div>
                <button style="flex: 1" [disabled]="waiting" mat-raised-button color="accent">Entrar</button>
            </div>
        </div>
    </form>
</div>
