import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as moment from "moment";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/moment";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        CalendarModule.forRoot({provide: DateAdapter, useFactory: () => adapterFactory(moment)})
    ],
    exports: []
})
export class PontoCalendarModule {
    constructor() {
        moment.updateLocale('pt', {
            week: {
                dow: 1, // set start of week to monday instead
                doy: 0,
            },
        });
    }
}
