import {Component, OnInit} from '@angular/core';
import {APP_VERSION} from "../version";
import {AuthenticationService} from "./security/authentication.service";
import {UserView} from "../model/model";
import {SubscriptionComponent} from "./shared/subscription-component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends SubscriptionComponent implements OnInit {

    date = new Date();

    version = APP_VERSION;

    user: UserView;

    constructor(private authentication: AuthenticationService) {
        super();
    }

    ngOnInit() {
        this.wrapSubscription(this.authentication.user()
            .subscribe(user => this.user = user));
    }

}


