import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HasPermissionDirective} from './has-permission-directive';
import {HasModuleDirective} from "./has-module-directive";
import {IsAuthenticatedDirective} from "./is-authenticated-directive";
import {AuthenticationService} from "./authentication.service";
import {PermissionService} from "./permission.service";
import {HttpClientModule} from "@angular/common/http";

@NgModule({
    imports: [
        RouterModule,
        HttpClientModule
    ],
    exports: [
        HasPermissionDirective,
        HasModuleDirective,
        IsAuthenticatedDirective
    ],
    declarations: [
        HasPermissionDirective,
        HasModuleDirective,
        IsAuthenticatedDirective
    ]
})
export class SecurityModule {

    static forRoot(): ModuleWithProviders<SecurityModule> {
        return {
            ngModule: SecurityModule,
            providers: [
                AuthenticationService,
                PermissionService
            ]
        };
    }

}
