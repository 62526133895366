import {Observable, ReplaySubject} from "rxjs";
import {Injectable} from "@angular/core";
import {RolePermissionView} from "./model";

@Injectable()
export class PermissionService {

    private _permissions = new ReplaySubject<RolePermissionView[]>(1);

    permissions(): Observable<RolePermissionView[]> {
        return this._permissions.asObservable();
    }

    set(permissions: RolePermissionView[]) {
        this._permissions.next(permissions);
    }

}
