<div class="mat-typography">
    <div [class]="type" style="display: flex; align-items: center">
        <div style="flex: 1"></div>
        <mat-icon>{{icon}}</mat-icon>
        <div style="flex: 1"></div>
    </div>
    <div class="inner-all">
        <div [innerHTML]="message"></div>
    </div>
    <div style="display: flex; align-items: center">
        <div style="flex: 1"></div>
        <button mat-raised-button color="warn" class="focus-me" (click)="dismiss()">Ok</button>
    </div>
</div>
