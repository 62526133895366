import {Injectable, TemplateRef} from '@angular/core';
import {PromptDialogComponent} from './components/prompt-dialog.component';
import {InputDialogComponent} from './components/input-dialog.component';
import {AutocompleteDialogComponent} from "./components/autocomplete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {InputAreaDialogComponent} from "./components/input-area-dialog.component";
import {Observable} from "rxjs";
import {HintProvider} from "../util/hint.provider";

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) {
  }

  public prompt(prompt: string, handler: (yesNo: boolean) => void, injectTemplate: TemplateRef<any> = null) {
    this.dialog.open(PromptDialogComponent, {
      data: {
        prompt,
        injectTemplate
      }
    }).afterClosed().subscribe(
      result => {
        if (handler) {
          handler(result);
        }
      }
    );
  }

  input(prompt: string,
        handler: (value: string) => void,
        value = null,
        placeholder: string = null) {
    this.dialog.open(InputDialogComponent, {
      width: '30vw',
      data: {
        prompt: prompt,
        placeholder: placeholder,
        value: value
      }
    }).afterClosed().subscribe(
      result => {
        if (result && handler) {
          handler(result);
        }
      }
    );
  }

  inputArea(prompt: string,
            handler: (value: string) => void,
            value = null,
            placeholder: string = null) {
    this.dialog.open(InputAreaDialogComponent, {
      width: '30vw',
      data: {
        prompt: prompt,
        placeholder: placeholder,
        value: value
      }
    }).afterClosed().subscribe(
      result => {
        if (result && handler) {
          handler(result);
        }
      }
    );
  }

  autocomplete<T>(prompt: string,
                  hintProvider: HintProvider<T>,
                  value = null,
                  placeholder: string = null,
                  noResultTemplate: TemplateRef<any> = null): Observable<T> {
    return this.dialog.open(AutocompleteDialogComponent, {
      width: '40vw',
      data: {
        prompt: prompt,
        placeholder: placeholder,
        value: value,
        hintProvider: hintProvider,
        noResultTemplate: noResultTemplate
      }
    }).afterClosed();
  }

}
