import {Component, Input} from '@angular/core';
import {HorarioSemanaEventWrapper} from "./horario-week-historico.component";
import {JustificacaoView} from "../../model/model";

@Component({
  selector: 'app-horario-week-justificacao-event',
  templateUrl: './horario-week-justificacao-event.component.html',
  styleUrls: ['./horario-week-justificacao-event.component.scss']
})
export class HorarioWeekJustificacaoEventComponent {

  @Input()
  wrapper: HorarioSemanaEventWrapper<JustificacaoView>;

  @Input()
  selected: boolean;

}
