import {Component, Input} from '@angular/core';
import {HorarioSemanaTurnoWrapper} from "./horario-week-historico.component";

@Component({
  selector: 'app-horario-week-turno-event',
  templateUrl: './horario-week-turno-event.component.html',
  styleUrls: ['./horario-week-turno-event.component.scss']
})
export class HorarioWeekTurnoEventComponent {

  @Input()
  wrapper: HorarioSemanaTurnoWrapper;

  @Input()
  selected: boolean;

}
