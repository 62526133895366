import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../security/authentication.service";
import {Subscription} from "rxjs";
import {finalize} from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoginOptions} from "./login.service";
import {APP_VERSION} from "../../version";

export class SiteInfo {
    name: string;
    loginUrl: string;
    mainUrl: string;
    method: 'BASIC' | 'POST';
    payloadType: 'JSON' | 'FORM_DATA' | 'URL_ENCODED';
    usernameParam: string;
    passwordParam: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    appVersion = APP_VERSION;

    username = new FormControl();
    password = new FormControl();

    form: FormGroup;
    error: any;

    subscriptions: Subscription[] = [];
    waiting = false;

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { options: LoginOptions },
                private http: HttpClient,
                private authenticationService: AuthenticationService,
                public dialogRef: MatDialogRef<LoginComponent>,
                private router: Router,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private formBuilder: FormBuilder) {
        this.form = formBuilder.group({
            username: this.username,
            password: this.password
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    login() {
        this.waiting = true;
        this.subscriptions.push(
            this.authenticationService.login(this.username.value, this.password.value, error => this.error = error)
                .pipe(finalize(() => this.waiting = false))
                .subscribe(user => {
                    if (user) {
                        this.dialogRef.close();
                        if (this.dialogData.options.onSuccess) {
                            this.dialogData.options.onSuccess.apply(user);
                        }
                    }
                })
        );
    }

}
