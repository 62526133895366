import * as moment from "moment";
import {Dates} from "./dates";
import {TurnoListView} from "../../model/model";

export interface HasOffsets {
    inicio: number;
    inicioLimite: number;
    fimLimite: number;
    fim: number;
}

export interface HasTurnoIntervalo {
    inicioTurno: string;
    inicioConta: string;
    inicio: string;
    inicioLimite: string;
    fimLimite: string;
    fim: string;
    fimConta: string;
    fimTurno: string;
}

export class Turnos {

    static prefill(refInicio: moment.Moment, dto: HasTurnoIntervalo) {
        if (refInicio.hour() <= 13) {
            dto.inicioTurno = '01:00';
            dto.inicioConta = refInicio.format('HH:mm');
            dto.inicio = refInicio.clone().add(1, 'hour').format('HH:mm');
            dto.inicioLimite = refInicio.clone().add(2, 'hour').format('HH:mm');

            dto.fimLimite = refInicio.clone().add(4, 'hour').format('HH:mm');
            dto.fim = refInicio.clone().add(4.5, 'hour').format('HH:mm');
            dto.fimConta = refInicio.clone().add(6, 'hour').format('HH:mm');
            dto.fimTurno = refInicio.clone().add(6.5, 'hour').format('HH:mm');
        } else {
            dto.inicioTurno = refInicio.clone().subtract(2, 'hour').format('HH:mm');
            dto.inicioConta = refInicio.clone().subtract(1.5, 'hour').format('HH:mm');
            dto.inicio = refInicio.format('HH:mm');
            dto.inicioLimite = refInicio.clone().add(0.5, 'hour').format('HH:mm');

            dto.fimLimite = refInicio.clone().add(3, 'hour').format('HH:mm');
            dto.fim = refInicio.clone().add(3.5, 'hour').format('HH:mm');
            dto.fimConta = refInicio.clone().add(6, 'hour').format('HH:mm');
            dto.fimTurno = '23:00';
        }
    }

    static offset(inicioOffset: number, fimOffset: number, dto: HasTurnoIntervalo) {
        Turnos.offsetInicio(inicioOffset, dto);
        Turnos.offsetFim(fimOffset, dto);
    }

    static offsetInicio(inicioOffset: number, dto: HasTurnoIntervalo) {
        dto.inicioTurno = Dates.fromTime(dto.inicioTurno).add(inicioOffset, 'minute').format('HH:mm');
        dto.inicioConta = Dates.fromTime(dto.inicioConta).add(inicioOffset, 'minute').format('HH:mm');
        dto.inicio = Dates.fromTime(dto.inicio).add(inicioOffset, 'minute').format('HH:mm');
        dto.inicioLimite = Dates.fromTime(dto.inicioLimite).add(inicioOffset, 'minute').format('HH:mm');
    }

    static offsetFim(fimOffset: number, dto: HasTurnoIntervalo) {
        dto.fimLimite = Dates.fromTime(dto.fimLimite).add(fimOffset, 'minute').format('HH:mm');
        dto.fim = Dates.fromTime(dto.fim).add(fimOffset, 'minute').format('HH:mm');
        dto.fimConta = Dates.fromTime(dto.fimConta).add(fimOffset, 'minute').format('HH:mm');
        dto.fimTurno = Dates.fromTime(dto.fimTurno).add(fimOffset, 'minute').format('HH:mm');
    }


    static toTurnoIntervalo(semanaTurno: TurnoListView): HasTurnoIntervalo {
        return {
            inicioTurno: Dates.asMoment(semanaTurno.inicioTurno).format('HH:mm'),
            inicioConta: Dates.asMoment(semanaTurno.inicioConta).format('HH:mm'),
            inicio: Dates.asMoment(semanaTurno.inicio).format('HH:mm'),
            inicioLimite: Dates.asMoment(semanaTurno.inicioLimite).format('HH:mm'),

            fimLimite: Dates.asMoment(semanaTurno.fimLimite).format('HH:mm'),
            fim: Dates.asMoment(semanaTurno.fim).format('HH:mm'),
            fimConta: Dates.asMoment(semanaTurno.fimConta).format('HH:mm'),
            fimTurno: Dates.asMoment(semanaTurno.fimTurno).format('HH:mm'),
        };
    }

    static calculateOffsets(turno: HasTurnoIntervalo): HasOffsets {
        const inicio = Dates.fromTime(turno.inicio);
        const inicioLimite = Dates.fromTime(turno.inicioLimite);

        const fimLimite = Dates.fromTime(turno.fimLimite);
        const fim = Dates.fromTime(turno.fim);

        const referenceInicio = inicio;
        const total = fim.diff(referenceInicio, 'second');
        return {
            inicio: inicio.diff(referenceInicio, 'second') / total,
            inicioLimite: inicioLimite.diff(referenceInicio, 'second') / total,
            fimLimite: 1 - fimLimite.diff(referenceInicio, 'second') / total,
            fim: 1
        };

    }

}
