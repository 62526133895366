import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nomePeriodoDia'
})
export class NomePeriodoDiaPipe implements PipeTransform {

  transform(value: string): string {
    return {
      TARDE: "Tarde",
      MANHA: "Manhã"
    }[value] || value;
  }

}
