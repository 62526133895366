import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {NotificationService} from './notification.service';
import {catchError} from 'rxjs/operators';
import {RestError} from './rest-error';
import {AuthenticationService} from "../security/authentication.service";
import {LoginService} from "../login/login.service";

@Injectable()
export class ErrorService {

    constructor(private notification: NotificationService,
                private authenticationService: AuthenticationService,
                private loginService: LoginService,
                private router: Router) {

    }

    handleHttpError(response: HttpErrorResponse) {
        // console.error(response.error);
        const error: RestError = response.error;
        switch (response.status) {
            case 502:
            case 503:
            case 504:
                this.notification.info('Serviço Indisponível');
                break;
            case 401:
                // console.warn(error.message);
                this.authenticationService
                    .logout(() => {
                        this.loginService.loginDialog();
                    });
                break;
            case 403:
                // console.warn(error.message);
                this.notification.error(`
                    <div>Não tem permissões para executar a operação!</div>
                    <div class="cp-text-muted">(recurso: ${response.url})</div>
                    `);
                break;
            case 404:
                this.notification.error(`
                    <div>O recurso não existe:</div>
                    <div class="inner-l">${response.url}</div>
                    `);
                break;
            default:
                console.error(response.error);
                this.notification.error(this.prepareMessage(response.error));
        }
        return response;
    }

    prepareMessage(payload: any): string {
        if (payload.errors && Array.isArray(payload.errors)) {
            let body = '';
            payload.errors.forEach(error => {
                body += error.defaultMessage ? `
                    <div>
                        <span class="cp-text-warn">&bullet;&nbsp;</span> ${error.defaultMessage}
                        <span class="cp-text-muted">(${error.field})</span>
                    </div>
                ` : `
                <div>
                    <span class="cp-text-warn">&bullet;&nbsp;</span> ${error}
                </div>`;
            });
            return body;
        }
        if (payload.message) {
            return payload.message;
        }
        if (payload.error) {
            return payload.status + ' ' + payload.error;
        }
        return payload;
    }

    wrapCall<T>(call: Observable<T>, onFail: (error: HttpErrorResponse) => void = () => null): Observable<T> {
        return call.pipe(catchError((error: HttpErrorResponse) => {
            try { onFail(error); } catch (e) { console.error(e); };
            return observableThrowError(this.handleHttpError(error));
        }));
    }

    // handleHttpError(response: HttpErrorResponse) {
    //     const error: RestError = response.error;
    //     this.snackBar.open(error.message, 'Ok', {
    //         verticalPosition: 'top'
    //     });
    //     console.error(response.error);
    // }

}
