import {ModuleWithProviders, NgModule} from "@angular/core";
import {InputDialogComponent} from "./components/input-dialog.component";
import {InputAreaDialogComponent} from "./components/input-area-dialog.component";
import {NotificationComponent} from "./notification.component";
import {PromptDialogComponent} from "./components/prompt-dialog.component";
import {AutocompleteDialogComponent} from "./components/autocomplete-dialog.component";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {DialogService} from "./dialog.service";
import {ErrorService} from "./error-service.service";
import {NotificationService} from "./notification.service";
import {ToolbarService} from "./toolbar.service";
import {RECAPTCHA_KEY, RecaptchaService} from "./recaptcha.service";
import {EventBusService} from "./event-bus.service";


@NgModule({
    declarations: [
        InputDialogComponent,
        InputAreaDialogComponent,
        PromptDialogComponent,
        NotificationComponent,
        AutocompleteDialogComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule
    ],
    exports: [AutocompleteDialogComponent]
})
export class ServicesModule {

    static forRoot(): ModuleWithProviders<ServicesModule> {
        return {
            ngModule: ServicesModule,
            providers: [
                DialogService,
                ErrorService,
                NotificationService,
                ToolbarService,
                RecaptchaService,
                EventBusService,
                {
                    provide: RECAPTCHA_KEY,
                    useValue: '6LdSaTgfAAAAADNnUi_arJPCeQTrcMzofRMCKOS5'
                }
            ]
        };
    }

}
