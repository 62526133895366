import * as moment from 'moment';

export class Dates {

  static DEFAULT_TIMEZONE = 'Europe/Lisbon';

  public static readonly HOUR_PATTERN = /^([0-9]{2}):([0-9]{2})$/;

  static isHour(value: string) {
    return Dates.HOUR_PATTERN.test(value);
  }

  static fromTime(hourSpec: string, ref: moment.Moment = moment.utc()) {
    const match = Dates.HOUR_PATTERN.exec(hourSpec);
    if (!match) return null;
    const hour = parseInt(match[1], 10);
    const minute = parseInt(match[2], 10);
    return ref.clone().hour(hour).minute(minute).second(0).millisecond(0);
  }


  static fromDate(value: string): Date {
    return Dates.asMoment(value).toDate();
  }

  static asMoment(value: string | Date) {
    return moment(value);
  }

  static now() {
    return moment.utc();
  }

  static momentDateString(value: string | Date) {
    return Dates.dateString(Dates.asMoment(value));
  }

  static dateString(value: moment.Moment) {
    return value.format('YYYY-MM-DD');
  }

  static hourString(value: moment.Moment) {
    return value.format('HH:mm');
  }

  static toDateTimeString(date: moment.Moment, hhmm: string, seconds: number = 0) {
    const secondsStr = seconds.toString().length < 2 ? `0${seconds}` : `${seconds}`;
    return `${Dates.dateString(date)}T${hhmm}:${secondsStr}`;
  }

}
