import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";

export class Event<T> {
    topic: string;
    data: T;
}

@Injectable({
    providedIn: 'root'
})
export class EventBusService {

    subjectMap: { string?: ReplaySubject<Event<any>> } = {};

    constructor() {
    }


    on(topic: string): Observable<Event<any>> {
        return this.subject(topic).asObservable();
    }

    publish(topic: string, data = {}) {
        this.subject(topic).next({
            topic: topic,
            data: data
        });
    }

    private subject(topic: string) {
        if (!this.subjectMap[topic])
            this.subjectMap[topic] = this.subjectMap[topic] || new ReplaySubject(1);
        return this.subjectMap[topic];
    }

}
