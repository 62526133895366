import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginRoutingModule} from './login-routing.module';
import {LoginComponent} from './login.component';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {LoginService} from "./login.service";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
    declarations: [LoginComponent],
    exports: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoginRoutingModule,
        MatButtonModule,
        MatInputModule,
        HttpClientModule,
        MatListModule,
        MatIconModule,
        MatDialogModule
    ]
})
export class LoginModule {

    static forRoot(): ModuleWithProviders<LoginModule> {
        return {
            ngModule: LoginModule,
            providers: [
                LoginService
            ]
        };
    }

}
