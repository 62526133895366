import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "./security/authentication.service";
import {SubscriptionComponent} from "./shared/subscription-component";
import {Router} from "@angular/router";
import {ErrorService} from "./services/error-service.service";
import {LoginService} from "./login/login.service";

@Component({
    selector: 'app-gateway',
    templateUrl: './gateway.component.html',
    styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent extends SubscriptionComponent implements OnInit {

    constructor(private authenticationService: AuthenticationService,
                private errorService: ErrorService,
                private loginService: LoginService,
                private router: Router) {
        super();
    }

    ngOnInit() {
        this.wrapSubscription(this.loginService.ensureAuthenticated({
            reason: 'gateway',
            onSuccess: user => {
                if (!user)
                    return;
                console.log(`Welcome: ${user.login}`);
                this.wrapSubscription(this.authenticationService.modules()
                    .subscribe(modules => {
                        if (!modules)
                            modules = [];
                        if (modules.filter(m => m.indexOf('gestao') === 0).length > 0) {
                            this.router.navigate(['gestao']).then();
                        } else if (modules.includes('perfil.historico')) {
                            this.router.navigate(['perfil']).then();
                        }
                    }));
            }
        }));
    }


}
