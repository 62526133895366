import {Component, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";

@Component({
    template: ''
})
export class SubscriptionComponent implements OnDestroy {

    private subscriptions: Subscription[] = [];

    wrapSubscription<T>(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
