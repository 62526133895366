import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {ActivationEnd, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ToolbarService {

    subTitleChanged = new ReplaySubject<string>();
    titleChanged = new ReplaySubject<string>();
    iconChanged = new ReplaySubject<string>();

    constructor(private router: Router) {
        router.events.subscribe(e => {
            if (e instanceof ActivationEnd) {
                this.subTitleChanged.next();
                this.titleChanged.next();
                this.iconChanged.next();
            }
        });
    }

    set icon(value: string) {
        this.iconChanged.next(value);
    }

    set subTitle(value: string) {
        this.subTitleChanged.next(value);
    }

    set title(value: string) {
        this.titleChanged.next(value);
    }

}