import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from "@angular/material/tabs";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MaterialHacks} from '../shared/material-hacks';
import {HorarioWeekEditorComponent} from './horario-week-editor.component';
import {CalendarCommonModule, CalendarWeekModule} from "angular-calendar";
import {HorarioWeekHistoricoComponent} from './horario-week-historico.component';
import {HorarioTemplatePreviewDialogComponent} from './horario-template-preview-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {ResumoSaldoComponent} from './resumo-saldo.component';
import {SharedModule} from "../shared/shared.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SecurityModule} from "../security/security.module";
import {PessoaFotoComponent} from './pessoa-foto.component';
import { HorarioWeekTurnoEventComponent } from './horario-week-turno-event.component';
import { HorarioWeekJustificacaoEventComponent } from './horario-week-justificacao-event.component';
import {DetalheTurnoDialogComponent} from "./detalhe-turno-dialog.component";
import {DetalheTurnoComponent} from "./detalhe-turno.component";
import { DetalheJustificacaoComponent } from './detalhe-justificacao.component';
import { DetalheJustificacaoDialogComponent } from './detalhe-justificacao-dialog.component';
import {HorarioWeekFeriasEventComponent} from "./horario-week-ferias-event.component";

@NgModule({
  declarations: [
    MaterialHacks,
    HorarioWeekEditorComponent,
    HorarioWeekHistoricoComponent,
    HorarioTemplatePreviewDialogComponent,
    HorarioWeekFeriasEventComponent,
    ResumoSaldoComponent,
    PessoaFotoComponent,
    HorarioWeekTurnoEventComponent,
    HorarioWeekJustificacaoEventComponent,
    DetalheTurnoComponent,
    DetalheTurnoDialogComponent,
    DetalheJustificacaoComponent,
    DetalheJustificacaoDialogComponent
  ],
  exports: [
    MaterialHacks,
    HorarioWeekEditorComponent,
    HorarioWeekHistoricoComponent,
    HorarioWeekFeriasEventComponent,
    ResumoSaldoComponent,
    PessoaFotoComponent,
    DetalheTurnoComponent,
    DetalheTurnoDialogComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    CalendarWeekModule,
    CalendarCommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    SharedModule,
    MatTooltipModule,
    SecurityModule
  ]
})
export class UiModule {
}
