import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements AfterViewInit {
    message: string;
    icon = 'info';
    type = 'info';

    constructor(@Inject(MAT_SNACK_BAR_DATA) private data: any,
                private dialog: MatSnackBarRef<NotificationComponent>) {
        this.message = data.message;
        this.type = data.type;
        switch (data.type) {
            case 'error':
            case 'warning':
                this.icon = 'warning';
                break;
            case 'success':
                this.icon = 'check';
                break;
            default:
                this.icon = 'info';
        }
    }

    ngAfterViewInit() {
        let el = document.getElementsByClassName("focus-me");
        if (el.length > 0) {
            (el.item(0) as HTMLButtonElement).focus();
        }
    }

    dismiss() {
        this.dialog.dismiss();
    }

}
