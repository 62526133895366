import {Injectable} from '@angular/core';
import {NotificationComponent} from './notification.component';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class NotificationService {

    constructor(private snackbar: MatSnackBar) {
    }

    error(message: string) {
        this.snackbar.openFromComponent(NotificationComponent, {
            data: {
                message,
                type: 'error'
            },
            verticalPosition: 'top'
        });
    }

    warning(message: string) {
        this.snackbar.openFromComponent(NotificationComponent, {
            data: {
                message,
                type: 'warning'
            },
            verticalPosition: 'top'
        });
    }

    info(message: string) {
        this.snackbar.openFromComponent(NotificationComponent, {
            data: {
                message
            },
            verticalPosition: 'top'
        });
    }
}
