import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LoginComponent} from "../login/login.component";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {AuthenticationService} from "../security/authentication.service";
import {EventBusService} from "../services/event-bus.service";
import {UserView} from "../../model/model";

export class LoginOptions {
    onSuccess?: (user: UserView) => void;
    params?: {};
    evadeCache?: boolean;
    reason?: string;
}

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    handle: MatDialogRef<LoginComponent, any>;
    dialogRef: MatDialogRef<LoginComponent>;

    constructor(private dialog: MatDialog,
                private router: Router,
                private eventBus: EventBusService,
                private authenticationService: AuthenticationService) {
        this.eventBus
            .on('login.dialog.request')
            .subscribe(event => {
                console.log('received event: ', event);
                this.loginDialog(event.data ? event.data.options : {});
            });
    }

    loginDialog(options: LoginOptions = {}) {
        if (this.dialogRef)
            this.dialogRef.close();

        console.log('Login requested, reason:', options.reason);
        this.dialogRef = this.dialog.open(LoginComponent, {
            minWidth: '320px',
            disableClose: true,
            data: {
                options: options
            }
        });
    }

    ensureAuthenticated(options: LoginOptions = {}): Subscription {
        return this.authenticationService.user(options.evadeCache)
            .subscribe(user => {
                if (user) {
                    if (options.onSuccess) {
                        options.onSuccess(user);
                    }
                } else {
                    this.loginDialog(options);
                }
            });
    }


}
