import {NgModule} from '@angular/core';
import {FirstLastPipe} from "./first-last.pipe";
import {UserMenuComponent} from './user-menu.component';
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";
import {ServicesModule} from "../services/services.module";
import {MatButtonModule} from "@angular/material/button";
import {HomeLinkComponent} from './home-link.component';
import {SecurityModule} from "../security/security.module";
import {SubscriptionComponent} from "./subscription-component";
import {AbstractFormComponent} from "./abstract-form.component";
import {EditorModule, TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";
import {ToolbarTitleComponent} from './toolbar-title.component';
import {LoginModule} from "../login/login.module";
import {DefaultToolbarComponent} from './default-toolbar.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {LpadPipe} from "./lpad.pipe";
import {ZoomDirective} from "./zoom.directive";
import { SignPipe } from './sign.pipe';
import {FirstUppercasePipe} from "./first-uppercase.pipe";
import {CollapseHintSpaceDirective} from "./material-hacks";
import { SaldoPipe } from './saldo.pipe';
import { MomentPipe } from './moment.pipe';
import { HintAutocompleteInputComponent } from './hint-autocomplete-input.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import { NomePeriodoDiaPipe } from './nome-periodo-dia.pipe';
import { SpacerComponent } from './spacer.component';

@NgModule({
    declarations: [
        LpadPipe,
        FirstLastPipe,
        UserMenuComponent,
        HomeLinkComponent,
        SubscriptionComponent,
        AbstractFormComponent,
        ToolbarTitleComponent,
        DefaultToolbarComponent,
        ZoomDirective,
        SignPipe,
        FirstUppercasePipe,
        CollapseHintSpaceDirective,
        SaldoPipe,
        MomentPipe,
        HintAutocompleteInputComponent,
        NomePeriodoDiaPipe,
        SpacerComponent
    ],
  exports: [
    LpadPipe,
    FirstLastPipe,
    UserMenuComponent,
    HomeLinkComponent,
    ToolbarTitleComponent,
    DefaultToolbarComponent,
    ZoomDirective,
    SignPipe,
    FirstUppercasePipe,
    CollapseHintSpaceDirective,
    SaldoPipe,
    MomentPipe,
    HintAutocompleteInputComponent,
    NomePeriodoDiaPipe,
    SpacerComponent
  ],
  imports: [
    ServicesModule,
    RouterModule,
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonModule,
    SecurityModule,
    EditorModule,
    LoginModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule
  ],
    providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
    ]
})
export class SharedModule {
}
