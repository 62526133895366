import {AfterViewInit, Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {debounceTime, map, mergeMap, startWith} from "rxjs/operators";
import {HintProvider} from "../../util/hint.provider";

@Component({
  selector: 'app-autocomplete-dialog',
  templateUrl: './autocomplete-dialog.component.html',
  styleUrls: ['./autocomplete-dialog.component.scss']
})
export class AutocompleteDialogComponent<T> implements AfterViewInit, OnInit {

  @ViewChild('control', {static: true})
  inputControl: ElementRef;

  form: FormGroup;

  valueProvider: Observable<T[]>;

  empty = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                prompt: string,
                placeholder: string,
                hintProvider: HintProvider<any>,
                value: any,
                noResultTemplate: TemplateRef<any>
              },
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<AutocompleteDialogComponent<T>>) {
    this.form = formBuilder.group({
      text: new FormControl(data.value)
    });
  }

  ngOnInit() {
    this.valueProvider = this.form.controls.text.valueChanges
      .pipe(
        startWith(''),
        debounceTime(150),
        mergeMap(value => this.data.hintProvider.getHintList(value)),
        map(values => {
          this.empty = values.length === 0;
          return values;
        })
      );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputControl.nativeElement.focus();
      this.inputControl.nativeElement.select();
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  ok() {
    this.dialogRef.close(this.form.controls.text.value);
  }

}
