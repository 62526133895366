<div class="semana-turno-calendar-event"
     [class.semana-turno-calendar-event-selected]="selected">
    <div class="cp-text-small semana-turno-top-left">{{wrapper.hours.inicio}}</div>
    <div class="cp-text-small semana-turno-bottom-right">{{wrapper.hours.fim}}</div>
    <div class="semana-turno-calendar-event-limite" style="left: 0; right: 0" [style.top.%]="wrapper.offsets.inicioLimite * 100" [style.bottom.%]="wrapper.offsets.fimLimite * 100">
        <div class="cp-text-small semana-turno-top-left">{{wrapper.hours.inicioLimite}}</div>
        <div class="cp-text-small semana-turno-bottom-right">{{wrapper.hours.fimLimite}}</div>
    </div>

    <div class="cp-text-small cp-text-muted semana-turno-top-right">
        <div style="display: flex; align-items: center">
            <i class="fa fa-arrow-right-to-bracket"></i>
            <div>&nbsp;</div>
            <div>{{wrapper.hours.inicioConta}}</div>
        </div>
    </div>
    <div class="cp-text-small cp-text-muted semana-turno-bottom-left">
        <div style="display: flex; align-items: center">
            <div>{{wrapper.hours.fimConta}}</div>
            <div>&nbsp;</div>
            <i class="fa fa-arrow-right-from-bracket"></i>
        </div>
    </div>

    <div class="semana-turno-content">
        <div style="display: flex; align-content: center">
            <div style="flex: 1"></div>
            <div class="semana-turno-title"
                 [class.cp-text-italic]="wrapper.turno.manual"
                 [class.cp-text-info]="wrapper.turno.manual">
                <b>{{wrapper.turno.descricao}}</b>
            </div>
            <div style="flex: 1"></div>
        </div>
        <div class="semana-turno-presenca" *ngIf="wrapper.turno.presenca">
            <!--            <pre>{{wrapper.turno.presenca | json}}</pre>-->
            <div [class]="wrapper.turno.presenca.tipoPresenca">
                <div style="display: flex; align-items: center; font-size: 0.8em">
                    <div style="flex: 1"></div>
                    <div *ngIf="wrapper.turno.presenca.presenca"><i class="fa fa-check"></i></div>
                    <div *ngIf="wrapper.turno.presenca.falta"><i class="fa fa-times"></i></div>
                    <div>&nbsp;</div>
                    <div *ngIf="wrapper.turno.presenca.saldo !== 0">{{wrapper.turno.presenca.saldo > 0 ? '+' : ''}}{{wrapper.turno.presenca.saldo}}m</div>
                    <sup class="inner-l cp-text-small cp-text-warn" *ngIf="wrapper.turno.presenca.saldoPausa">-{{wrapper.turno.presenca.saldoPausa * 1000 * 60 | date:'m:ss'}}m</sup>
                    <div style="flex: 1"></div>
                </div>
                <div style="display: flex; align-items: center; font-size: 0.7em; padding: 0 1em">
                    <div style="flex: 1"></div>
                    <div *ngIf="wrapper.turno.presenca.inicioOverride">
                        <i class="fa fa-user-edit"></i>&nbsp;{{wrapper.turno.presenca.inicioOverride}}
                    </div>
                    <div *ngIf="!wrapper.turno.presenca.inicioOverride && wrapper.turno.presenca.inicioEventTime">
                        <i class="fa fa-arrow-right-to-bracket"></i>&nbsp;{{wrapper.turno.presenca.inicioEventTime | date:'HH:mm'}}
                    </div>
                    <div>&nbsp;</div>
                    <div *ngIf="wrapper.turno.presenca.fimOverride">
                        <i class="fa fa-user-edit"></i>&nbsp;{{wrapper.turno.presenca.fimOverride}}
                    </div>
                    <div *ngIf="!wrapper.turno.presenca.fimOverride && wrapper.turno.presenca.fimEventTime">
                        <i class="fa fa-arrow-right-from-bracket"></i>&nbsp;{{wrapper.turno.presenca.fimEventTime | date:'HH:mm'}}
                    </div>
                    <div style="flex: 1"></div>
                </div>
            </div>
        </div>
    </div>
</div>