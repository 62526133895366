import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TurnoListView} from "../../model/model";
import {CalendarEvent, CalendarWeekViewComponent} from "angular-calendar";
import {SelectionModel} from "@angular/cdk/collections";
import {Subject} from "rxjs";
import {Dates} from "../util/dates";
import {Turnos} from "../util/turnos";
import {MatDialog} from "@angular/material/dialog";
import * as moment from "moment";

export type TurnoListClickEvent = { turno: TurnoListView };

export class HorarioSemanaTurnoWrapper {
  turno: TurnoListView;
  templateName: string;
  tooltip: string;
  offsets: {
    inicio: number,
    inicioLimite: number,
    fimLimite: number,
    fim: number,
  };
  hours: {
    inicioTurno: string,
    inicioConta: string,
    inicio: string,
    inicioLimite: string,
    fimLimite: string,
    fim: string,
    fimConta: string,
    fimTurno: string
  };
}

export class HorarioSemanaEventWrapper<T> {
  eventId: number;
  templateName: string;
  title: string;
  tooltip?: string;
  data: T;
  allDay?: boolean;
  hours: {
    inicio: moment.Moment,
    fim: moment.Moment
  };
}

@Component({
  selector: 'app-horario-week-historico',
  templateUrl: './horario-week-historico.component.html',
  styleUrls: ['./horario-week-historico.component.scss']
})
export class HorarioWeekHistoricoComponent implements OnInit {

  @ViewChild('weekView')
  weekView: CalendarWeekViewComponent;

  selectionModel = new SelectionModel<string | number>();

  calendarRefresh = new Subject<void>();

  refresh() {
    this.calendarRefresh.next();
  }

  @Input()
  viewDate = new Date();

  @Output()
  turnoClicked = new EventEmitter<TurnoListClickEvent>();

  @Output()
  extraEventClicked = new EventEmitter<{ data?: any }>();

  @Output()
  hourSegmentClicked = new EventEmitter<{ date: Date; sourceEvent: MouseEvent; }>();

  @Input()
  dayStartHour = 0;

  @Input()
  dayEndHour = 24;

  eventMap: { number?: CalendarEvent<HorarioSemanaTurnoWrapper>; } = {};
  extraEventMap: { number?: CalendarEvent<HorarioSemanaEventWrapper<any>>; } = {};

  _allEvents: CalendarEvent[] = [];
  private _events: CalendarEvent<HorarioSemanaTurnoWrapper>[] = [];
  private _extraEvents: CalendarEvent<HorarioSemanaEventWrapper<any>>[] = [];


  set events(value: CalendarEvent<HorarioSemanaTurnoWrapper>[]) {
    this._events = value;
    this._allEvents = [].concat(this._extraEvents).concat(value); // remove old _events
  }

  get events(): CalendarEvent<HorarioSemanaTurnoWrapper>[] {
    return this._events;
  }

  set extraEvents(value: CalendarEvent<HorarioSemanaEventWrapper<any>>[]) {
    this._extraEvents = value;
    this._allEvents = [].concat(this._events).concat(value); // remove old _extraEvents
  }

  get extraEvents(): CalendarEvent<HorarioSemanaEventWrapper<any>>[] {
    return this._extraEvents;
  }

  get allEvents() {
    return this._allEvents;
  }

  @Input()
  set eventList(value: TurnoListView[]) {
    this._events.length = 0;
    this.eventMap = {};
    if (!value)
      return;
    const eventList = value || [];
    this.events = eventList.map(e => this.fromSemanaTurno(e));
    this.eventMap = this.events.reduce((p, c) => Object.assign(p, {[c.meta.turno.turnoId]: c}), {});
    this.calendarRefresh.next();
  }

  @Input()
  set extraEventList(value: HorarioSemanaEventWrapper<any>[]) {
    this._extraEvents.length = 0;
    this.extraEventMap = {};
    if (!value)
      return;
    const extraEventList = value || [];
    this.extraEvents = extraEventList.map(e => this.fromExtraEvent(e));
    this.extraEventMap = this.extraEvents.reduce((p, c) => Object.assign(p, {[c.id]: c}), {});
    this.calendarRefresh.next();
  }

  constructor(private dialog: MatDialog) {

  }


  ngOnInit() {
    this.selectionModel.changed.subscribe(() => {
      const eventId = this.selectionModel.selected[0];
      const event = this.eventMap[eventId];
      if (event && event.meta)
        this.turnoClicked.emit({
          turno: event.meta.turno
        });

      const extraEvent = this.extraEventMap[eventId];
      if (extraEvent && extraEvent.meta) {
        this.extraEventClicked.emit({
          data: extraEvent.meta.data
        });
      }

      this.refresh();
    });
  }

  fromExtraEvent(x: HorarioSemanaEventWrapper<any>): CalendarEvent<HorarioSemanaEventWrapper<any>> {
    return {
      id: x.eventId,
      title: x.title,
      start: x.hours.inicio.toDate(),
      end: x.hours.fim.toDate(),
      meta: x,
      allDay: x.allDay
    };
  }

  fromSemanaTurno(semanaTurno: TurnoListView): CalendarEvent<HorarioSemanaTurnoWrapper> {
    return {
      id: semanaTurno.turnoId,
      title: semanaTurno.descricao,
      start: Dates.fromDate(semanaTurno.inicio),
      end: Dates.fromDate(semanaTurno.fim),
      meta: {
        templateName: 'turno',
        turno: semanaTurno,
        tooltip: semanaTurno.descricao,
        offsets: Turnos.calculateOffsets(Turnos.toTurnoIntervalo(semanaTurno)),
        hours: {
          inicioTurno: Dates.asMoment(semanaTurno.inicioTurno).format('HH:mm'),
          inicioConta: Dates.asMoment(semanaTurno.inicioConta).format('HH:mm'),
          inicio: Dates.asMoment(semanaTurno.inicio).format('HH:mm'),
          inicioLimite: Dates.asMoment(semanaTurno.inicioLimite).format('HH:mm'),
          fimLimite: Dates.asMoment(semanaTurno.fimLimite).format('HH:mm'),
          fim: Dates.asMoment(semanaTurno.fim).format('HH:mm'),
          fimConta: Dates.asMoment(semanaTurno.fimConta).format('HH:mm'),
          fimTurno: Dates.asMoment(semanaTurno.fimTurno).format('HH:mm'),
        }
      },
      color: {
        secondaryText: 'white',
        primary: '#7171b4',
        secondary: '#8484cc'
      }
    };
  }

  handleEventClick(event: { event: CalendarEvent; sourceEvent: MouseEvent | KeyboardEvent }) {
    this.selectionModel.select(event.event.id);
  }

  handleSegmentClicked(event: { date: Date; sourceEvent: MouseEvent }) {
    this.hourSegmentClicked.emit(event);
  }

  clearSelection() {
    this.selectionModel.clear();
  }

}
