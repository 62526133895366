<div class="mat-typography" mat-dialog-content>
    <form (submit)="ok()" [formGroup]="form" #theForm>
        <div [innerHTML]="this.data.prompt"></div>
        <mat-form-field style="width: 100%">
            <input matInput id="text" [placeholder]="this.data.placeholder" formControlName="text" #control [matAutocomplete]="autoInput"/>
        </mat-form-field>
        <mat-autocomplete #autoInput="matAutocomplete" [displayWith]="this.data.hintProvider.renderSelecttion">
            <mat-option *ngFor="let value of valueProvider | async" [value]="value" [innerHTML]="this.data.hintProvider.renderItem(value)">
            </mat-option>
        </mat-autocomplete>
    </form>
</div>
<div mat-dialog-actions style="display: flex; align-items: center">
    <div *ngIf="data.noResultTemplate && empty && control.value">
        <ng-template *ngTemplateOutlet="data.noResultTemplate; context: {value: control.value, dialog: dialogRef}"></ng-template>
    </div>
    <div style="flex: 1"></div>
    <button type="button" mat-button (click)="cancel()" mat-raised-button tabindex="-1" color="warn">Cancelar</button>
    <div>&nbsp;</div>
    <button (click)="ok()" [disabled]="!control.value" mat-button mat-raised-button tabindex="2">Ok</button>
</div>
