import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GatewayComponent} from "./gateway.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'gateway'
  },
  {path: 'gateway', component: GatewayComponent},
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'testing', loadChildren: () => import('./testing/testing.module').then(m => m.TestingModule)},
  {path: 'gestao', loadChildren: () => import('./gestao/gestao.module').then(m => m.GestaoModule)},
  {path: 'runas', loadChildren: () => import('./runas/runas.module').then(m => m.RunasModule)},
  {path: 'perfil', loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

